<script setup lang="ts">
import { useCollapse } from '../composables/collapse'

const { isOpen, isMobileOpen, toggle } = useCollapse()
const { xl } = useTailwindBreakpoints()
</script>

<template>
  <button
    v-if="!xl"
    type="button"
    class="flex h-10 w-10 items-center justify-center"
    @click="() => toggle()"
  >
    <icon name="ph:list"></icon>
  </button>
</template>
